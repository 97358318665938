<template>
  <v-footer
    dark
    color="secondary   pl-0 pr-0 pb-0"
    :class="{'d-none': $route.path == '/home-two'}"
  >
    <v-container class="mb-5">
      <v-row>
        <v-col
          cols="12"
          xl="4"
          lg="4"
          md="6"
          sm="12"
        >
          <!--          <v-avatar width="150" tile class="mb-5">
            <img src="@/assets/images/logo.png" alt="" />
          </v-avatar>-->
          <newsletter />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="2"
          md="6"
          sm="12"
        >
          <h2 class="mb-5">
            {{ $t("AIDES") }}
          </h2>
          <div
            v-for="(n, index) in $store.state.settings.help || []"
            :key="index"
            class="my-1"
          >
            <router-link
              :to="'/faq/'+n.slug"
              class="text-decoration-none footer-link-hover"
            >
              {{
                n.name
              }}
            </router-link>
          </div>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="12"
        >
          <h2 class="mb-5">
            {{ $t("INFORMATIONS") }}
          </h2>
          <div
            v-for="(n, index) in $store.state.settings.information || []"
            :key="index"
            class="my-1"
          >
            <router-link
              :to="'/info/'+n.key"
              class="text-decoration-none footer-link-hover"
            >
              {{
                n.title
              }}
            </router-link>
          </div>
          <v-btn
            color="primary"
            class="mt-1"
            depressed
            to="/profile"
          >
            {{ $t("Devenez un pro") }}
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="12"
        >
          <h2 class="mb-5">
            {{ $t("Contactez nous") }}
          </h2>
          <p
            class="footer-text"
            v-html="$store.state.settings.address"
          >
          </p>
          <p class="footer-text">
            {{ $t("Email:") }} {{ $store.state.settings.email || 'info@planete-market.eu' }}
          </p>
          <p class="footer-text mb-5">
            {{ $t("Phone:") }} {{ $store.state.settings.tel }}
          </p>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="me-2 white--text"
            elevation="0"
            x-small
            fab
            dark
            color="secondary darken-1"
          >
            <v-icon
              small
              size="24px"
            >
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="secondary darken-1 w-100  mt-5">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex align-center"
          >
            Copyright © {{ new Date().getFullYear() }} {{ $t("Tous les droits sont réservés.") }}
          </v-col>
          <v-col
            cols="12"
            md="6"
            class=""
          >
            <v-img
              class=""
              height="35"
              contain
              src="/image_payment_footer-min.png"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-footer>
</template>
<script>
  import Newsletter from '../../components/Newsletter.vue'

  export default {
    name: 'Footer',
    components: { Newsletter },
    data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-youtube',
        'mdi-google',
        'mdi-instagram',
      ],
      aboutUs: [
        'Careers',
        'Our Stores',
        'Our Cares',
        'Terms & Conditions',
        'Privacy Policy',
      ],
      customerCare: [
        'Help Center',
        'How to Buy',
        'Track Your Order',
        'Corporate & Bulk Purchasing',
        'Returns & Funds',
      ],
    }),
  }
</script>
<style lang="scss">
a.footer-link-hover {
  color: #bdbdbd !important;
  &:hover {
    color: #fff !important;
  }
}
.v-footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
</style>
